import { getHumanReadableDateShort } from '@/utils/date'
import CopyToClipboard from '@/components/cards/CopyToClipboard'

const UserMixin = {
  components: {
    'copy-to-clipboard': CopyToClipboard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isLoading: false,
      user: null,
    }
  },
  computed: {
    userAvatarImage () {
      return `${process.env.VUE_APP_API_DOMAIN}/v1/users/${this.id}/picture`
    },
  },
  methods: {
    getHumanReadableDateShort,
    getUser () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUser', this.id)
        .then(user => {
          this.user = user
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    viewUser () {
      this.$router.push({ name: 'appDetails', params: { id: this.id } })
    },
  },
  watch: {
    user: {
      handler () {
        if (this.user) return
        this.getUser()
      },
      immediate: true,
    },
  },
}

export {
  UserMixin,
}
